@tailwind base;

/* Write your own custom base styles here */

input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
}

/* Start purging... */
@tailwind components;

html,
body {
  @apply bg-gray-50 font-default;
  @apply text-info-600;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-headings;
}

button {
  @apply focus:outline-none;
}

/* Start purging... */
@tailwind utilities;

/* Your own custom utilities */
